import * as Sentry from '@sentry/browser';
import 'url-search-params-polyfill';
import ResizeObserver from 'resize-observer-polyfill';

import './sw';

import { queryStringToObject, objectToQueryString } from 'utils/queryString';
import mixpanel from 'utils/mixpanel';

import { version } from '../package.json';

// ResizeObserver polyfill (for IE and Safari)
window.ResizeObserver = ResizeObserver;

const { SENTRY_DSN, ATHENA_URL, MIXPANEL_TOKEN } = process.env;

export const ERRORS_VALUE_BLACKLIST = [
  'Loading CSS chunk',
  'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
];

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: version,
    ignoreErrors: [...ERRORS_VALUE_BLACKLIST],
  });
}

const { id_token: idTokenFromUrl = null, state: stateFromUrl = null } = queryStringToObject(
  window.location.hash.split('#')[1],
);

// sso detection
if (idTokenFromUrl && stateFromUrl) {
  const {
    authType: authTypeFromStateUrl,
    clientId: clientIdFromStateUrl,
    groupId: groupIdFromStateUrl,
    language: languageFromStateUrl,
    callbackSuccess: callbackSuccessFromStateUrl,
    webview: webviewFromStateUrl,
    mixpanelDistinctId: mixpanelDistinctIdFromStateUrl,
    withChallengeRegistrationModal: withChallengeRegistrationModalFromUrl,
    isAuthorizedAuthProvider: isAuthorizedAuthProviderFromUrl,
    deviceId: deviceIdFromStateUrl,
    deviceName: deviceNameFromStateUrl,
    referralId: referralIdFromStateUrl,
  } = queryStringToObject(stateFromUrl);

  if (clientIdFromStateUrl && languageFromStateUrl && callbackSuccessFromStateUrl) {
    const query = {
      'client-id': clientIdFromStateUrl,
      language: languageFromStateUrl,
      callback_success: callbackSuccessFromStateUrl,
    };

    if (authTypeFromStateUrl) {
      query.authType = authTypeFromStateUrl;
    }

    if (isAuthorizedAuthProviderFromUrl === 'true') {
      query.isAuthorizedAuthProvider = true;
    }

    if (mixpanelDistinctIdFromStateUrl) {
      query['mixpanel-distinct-id'] = mixpanelDistinctIdFromStateUrl;
    }

    if (groupIdFromStateUrl) {
      query['group-id'] = groupIdFromStateUrl;
    }

    if (deviceIdFromStateUrl && deviceNameFromStateUrl) {
      query['device-id'] = deviceIdFromStateUrl;
      query['device-name'] = deviceNameFromStateUrl;
    }

    if (webviewFromStateUrl) {
      query.webview = '1';
    }

    if (withChallengeRegistrationModalFromUrl === 'true') {
      query.withChallengeRegistrationModal = true;
    }

    if (idTokenFromUrl) {
      query['sso-token'] = idTokenFromUrl;
    }

    if (referralIdFromStateUrl) {
      query['referral-id'] = referralIdFromStateUrl;
    }

    const queryString = objectToQueryString(query);

    const athenaRedirectUrl = `${ATHENA_URL}?${queryString}`;

    window.location.href = athenaRedirectUrl;
  }
} else {
  (async () => {
    // Init Mixpanel
    if (MIXPANEL_TOKEN) {
      try {
        mixpanel.init(MIXPANEL_TOKEN);
      } catch (err) {} // eslint-disable-line no-empty
    }

    import('./main');
  })();
}
