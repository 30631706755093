export const queryStringToObject = queryString => {
  const result = {};
  Array.from(new URLSearchParams(queryString).entries()).forEach(paramArray => {
    const [key, value] = paramArray;
    result[key] = value;
  });
  return result;
};

export const objectToQueryString = object => {
  // removes null or undefined values
  const cleanObject = Object.entries(object).reduce(
    (acc, [key, value]) =>
      value !== undefined && value !== null
        ? {
            ...acc,
            [key]: value,
          }
        : acc,
    {},
  );

  return new URLSearchParams(cleanObject).toString();
};

const extractQueryParameters = url => {
  const [, parameters] = url.split('?');
  if (!parameters) {
    return {};
  }
  return queryStringToObject(decodeURIComponent(parameters));
};

const extractUrl = param => {
  const [url] = param.split('?');
  return url;
};

const addQueryParameters = (url, queryObject) => {
  const params = extractQueryParameters(url);

  const finalParams = {
    ...params,
    ...queryObject,
  };

  return `${extractUrl(url)}?${objectToQueryString(finalParams)}`;
};

export const mergeQueryParametersWithFullUrl = (url, redirectQueryParams) => {
  const params = extractQueryParameters(url);

  if (
    url.includes('https://links.sportheroes.com/') &&
    Object.keys(params).includes('web') &&
    Object.keys(params).includes('app')
  ) {
    const web = addQueryParameters(params.web, redirectQueryParams);
    const app = addQueryParameters(params.app, redirectQueryParams);

    const finalCallbackParams = {
      ...params,
      app,
      web,
    };

    const finalRedirect = `${extractUrl(url)}?${objectToQueryString(finalCallbackParams)}`;
    return finalRedirect;
  }

  const redirect = addQueryParameters(url, redirectQueryParams);

  return redirect;
};
